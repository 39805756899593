import { createRouter, createWebHistory } from 'vue-router'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main',
      component: () => import('../components/views/Home.vue')
    },
    {
      path: '/auth',
      name: 'auth',
      component: () => import('../components/views/Auth.vue')
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('../components/views/Register.vue')
    },
    {
      path: '/invite',
      name: 'invite',
      component: () => import('../components/views/Invite.vue')
    },
    {
      path: '/reports',
      name: 'reports',
      component: () => import('../components/views/Reports.vue')
    },
    {
      path: '/edit',
      name: 'edit',
      component: () => import('../components/views/Edit.vue')
    },
    {
      path: '/news',
      name: 'news',
      component: () => import('../components/views/News.vue')
    }
  ]
})

export default router
