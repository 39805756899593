import './assets/main.css'

import { createApp } from 'vue'

import * as Sentry from '@sentry/vue'
import { createPinia } from 'pinia'
import { createI18n } from 'vue-i18n'
import ToastPlugin from 'vue-toast-notification'

import 'vue-toast-notification/dist/theme-bootstrap.css'

import en from '../localizations/en.json'
import es from '../localizations/es.json'
import fi from '../localizations/fi.json'
import hr from '../localizations/hr.json'
import nl from '../localizations/nl.json'
import pt from '../localizations/pt.json'
import App from './App.vue'
import router from './router'

const i18n = createI18n({
  legacy: false,
  locale: 'en',
  messages: {
    en,
    hr,
    nl,
    pt,
    fi,
    es
  }
})

const app = createApp(App)
app.config.globalProperties.$imageUrl = import.meta.env.VITE_IMAGE_URL

app.use(createPinia())
app.use(i18n)
app.use(router)
app.use(ToastPlugin)

Sentry.init({
  app: app,
  dsn: 'https://d67dbd5f225cf213551017658ac8ae2f@o4506735405039616.ingest.us.sentry.io/4506735407202304',
  integrations: []
})

app.mount('#app')
