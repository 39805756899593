<template>
  <router-view></router-view>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'

import { v4 } from 'uuid'

onMounted(() => {
  const userId = localStorage.getItem('userId')

  if (!userId) {
    localStorage.setItem('userId', v4())
  }
})
</script>
